import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyB08A5sB4pWGLq4b5_-T1naUC7vv9ILjds',
  authDomain: 'auto-clean-and-green.firebaseapp.com',
  projectId: 'auto-clean-and-green',
  storageBucket: 'auto-clean-and-green.appspot.com',
  messagingSenderId: '934453905931',
  appId: '1:934453905931:web:949cf9c5acbd03ee0e950e'
};

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export default app;
