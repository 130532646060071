// assets
import { IconDashboard, IconBell, IconBook } from '@tabler/icons';
// constant
const icons = { IconDashboard, IconBell, IconBook };

const dashboard = {
  id: 'dashboard',
  title: 'Panel Principal',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Panel Principal',
      type: 'item',
      url: '/app/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'courses',
      title: 'Cursos',
      type: 'item',
      url: '/app/courses',
      icon: icons.IconBook,
      breadcrumbs: false
    },
    {
      id: 'free-courses',
      title: 'Cursos Gratuitos',
      type: 'item',
      url: '/app/free-courses',
      icon: icons.IconBook,
      breadcrumbs: false
    },
    {
      id: 'notifications',
      title: 'Notificaciones',
      type: 'item',
      url: '/app/notifications',
      icon: icons.IconBell
    }
  ]
};

export default dashboard;
