// assets
import { IconSettings, IconPhotoUp, IconFileReport, IconPhotoPlus, IconTopologyStar2, IconFile } from '@tabler/icons';

// constant
const icons = {
  IconSettings,
  IconPhotoUp,
  IconFileReport,
  IconPhotoPlus,
  IconTopologyStar2,
  IconFile
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  title: 'Configuraciones',
  type: 'group',
  children: [
    {
      id: 'banner',
      title: 'Banners',
      type: 'item',
      url: '/admin/banner',
      icon: icons.IconPhotoUp
    },
    {
      id: 'publicity',
      title: 'Publicidad',
      type: 'item',
      url: '/admin/publicity',
      icon: icons.IconPhotoPlus
    },
    {
      id: 'flow',
      title: 'Flujo Servicio',
      type: 'item',
      url: '/admin/flow',
      icon: icons.IconTopologyStar2,
      breadcrumbs: false
    },
    {
      id: 'documents',
      title: 'Documentos',
      type: 'item',
      url: '/admin/documents',
      icon: icons.IconFile
    },
    {
      id: 'settings',
      title: 'Parámetros',
      type: 'item',
      url: '/admin/settings',
      icon: icons.IconSettings
    },
    {
      id: 'logs',
      title: 'Logs',
      type: 'item',
      url: '/admin/logs',
      icon: icons.IconFileReport,
      breadcrumbs: false
    }
  ]
};

export default pages;
