//Firebase
import { db, authentication } from 'config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, setDoc, doc, updateDoc, deleteDoc, getDocs, addDoc, where, query } from 'firebase/firestore';
import {
  collAdminUsers,
  collAdvertising,
  collAgencies,
  collBanners,
  collBillData,
  collBusiness,
  collCars,
  collCities,
  collComments,
  collCourses,
  collDetailing,
  collDocuments,
  collGenNoti,
  collLog,
  collModules,
  collOrders,
  collRegUsr,
  collServices,
  collSubscription,
  collUsers,
  collUsrNoti
} from 'store/collections';
import { genConst } from 'store/constant';
import { labels } from 'store/labels';
import { generateId } from 'utils/idGenerator';
import { generateDate } from 'utils/validations';

//Encontrar Sesión activa
export function isSessionActive(navigate) {
  onAuthStateChanged(authentication, (user) => {
    if (user) {
      getProfileUser(user.uid).then((pro) => {
        if (pro == genConst.CONST_PRO_ADM) {
          navigate('/admin/dashboard');
        } else {
          navigate('/app/dashboard');
        }
      });
    }
  });
}

//Encontrar Id de Usuario Sesión
export function getUserId() {
  let userId = null;
  onAuthStateChanged(authentication, (user) => {
    if (user) {
      userId = user.uid;
    }
  });
  return userId;
}

//CRUD FUNCTIONS
export function createDocument(table, idRecord, object) {
  return setDoc(doc(db, table, idRecord), object);
}
export function updateDocument(table, idRecord, object) {
  return updateDoc(doc(db, table, idRecord), object);
}
export function deleteDocument(table, idRecord) {
  return deleteDoc(doc(db, table, idRecord));
}
export function getDocuments(table) {
  return getDocs(collection(db, table));
}
export function createLogRecord(object) {
  return addDoc(collection(db, collLog), object);
}
export function createSystemNotification(object) {
  return addDoc(collection(db, collUsrNoti), object);
}
export function createLogRecordWithId(idRecord, object) {
  return setDoc(doc(db, collLog, idRecord), object);
}
export function createGlobalNotification(message, subject) {
  const object = {
    id: generateId(6),
    from: labels.notiAdmin,
    date: generateDate(),
    message: message,
    subject: subject,
    state: genConst.CONST_NOTIF_NL
  };
  return addDoc(collection(db, collGenNoti), object);
}
//Obtener Datos Perfil de Usuario por ID
export async function getProfileUser(id) {
  let profile = null;
  const q = query(collection(db, collUsers), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    profile = doc.data().profile;
  });
  return profile;
}
//Obtener Datos Perfil de Usuario Administrador por ID
export async function getProfileUserAdmin(id) {
  let profile = null;
  const q = query(collection(db, collAdminUsers), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    profile = doc.data().profile;
  });
  return profile;
}
//Obtener el Estado de una Usuario por ID
export async function getSubscribeStateUser(id) {
  let state = null;
  const q = query(collection(db, collUsers), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    state = doc.data().state;
  });
  return state;
}
//Obtener el estado de la Subscripción de un Usuario por ID
export async function getUserSubscription(id) {
  let state = null;
  const q = query(collection(db, collSubscription), where('idUser', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    state = doc.data().state;
  });
  return state;
}
//Obtner el Negocio por Id
export async function getBusinessById(id) {
  let data = [];
  const q = query(collection(db, collBusiness), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
}
//LISTAS
//Obtenemos la lista de Usuarios
export const getUsersData = async () => {
  const list = [];
  const querySnapshot = await getDocs(collection(db, collUsers));
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
    list.sort((a, b) => a.name.localeCompare(b.name));
  });
  return list;
};
//Obtenemos la lista de Banners
export const getBanners = async () => {
  const list = [];
  const querySnapshot = await getDocs(collection(db, collBanners));
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Obtenemos la lista de Anuncion
export const getAdvertisement = async () => {
  const list = [];
  const querySnapshot = await getDocs(collection(db, collAdvertising));
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Obtenemos la lista de Documentos
export const getDocumentFiles = async () => {
  const list = [];
  const querySnapshot = await getDocs(collection(db, collDocuments));
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//
export const getGeneralNotifications = async () => {
  const list = [];
  const querySnapshot = await getDocuments(collGenNoti);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Obtenemos la lista de Usuarios Administradores
export const getAdminUsersData = async () => {
  const list = [];
  const q = query(collection(db, collUsers), where('profile', '==', genConst.CONST_PRO_ADM));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Obtenemos la lista de Usuarios Administradores
export const getUsersList = async () => {
  const list = [];
  const q = query(collection(db, collUsers), where('profile', '==', genConst.CONST_PRO_STU));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Obtenemos la lista de Usuarios Administradores
export const getUsersBills = async (id) => {
  const list = [];
  const q = query(collection(db, collBillData), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Obtenemos la lista de Usuarios Administradores
export const getOperatorsList = async () => {
  const list = [];
  const q = query(collection(db, collUsers), where('profile', '==', genConst.CONST_PRO_OPE));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Obtenemos la lista de Vehiculos
export const getCarsList = async () => {
  const list = [];
  const querySnapshot = await getDocs(collection(db, collCars));
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
    list.sort((a, b) => a.brand.localeCompare(b.brand));
  });
  return list;
};
//Obtenemos la lista de Comentarios por Operador
export const getOperatorCommetList = async (id) => {
  const list = [];
  const q = query(collection(db, collComments), where('operatorId', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Obtenemos el saldo por Operador
export const getOperatorAmount = async (id) => {
  let amount = 0;
  const q = query(collection(db, collOrders), where('operatorId', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    amount += parseFloat(doc.data().total);
  });
  return amount;
};
//Obtenemos la lista de Agencias
export const getAgenciesList = async () => {
  const list = [];
  const querySnapshot = await getDocs(collection(db, collAgencies));
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Obtenemos la lista de Ciudades
export const getCitiesList = async () => {
  const list = [];
  const querySnapshot = await getDocs(collection(db, collCities));
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Obtenemos la lista de Servicios
export const getServicesList = async () => {
  const list = [];
  const querySnapshot = await getDocs(collection(db, collServices));
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
    list.sort((a, b) => a.order - b.order);
  });
  return list;
};
//Obtenemos la lista de Ordenes de Servicio
export const getOrdersList = async () => {
  const list = [];
  const querySnapshot = await getDocs(collection(db, collOrders));
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Obtenemos el monto de la lista de Ordenes de Servicio
export const getAmountOrdersList = async () => {
  let amount = 0;
  const querySnapshot = await getDocs(collection(db, collOrders));
  querySnapshot.forEach((doc) => {
    amount += parseFloat(doc.data().total);
  });
  return amount;
};
//Obtenemos la lista de Detailing
export const getDetailingList = async () => {
  const list = [];
  const querySnapshot = await getDocs(collection(db, collDetailing));
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
    list.sort((a, b) => a.order - b.order);
  });
  return list;
};
//Obtener lista de Negocios
export async function getBusinessList() {
  const list = [];
  const querySnapshot = await getDocs(collection(db, collBusiness));
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
    list.sort((a, b) => a.name.localeCompare(b.name));
  });
  return list;
}
//Obtenemos la Lista de Cursos
export const getCoursesList = async () => {
  const list = [];
  const querySnapshot = await getDocuments(collCourses);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
    list.sort((a, b) => a.name.localeCompare(b.name));
  });
  return list;
};
//Obtenemos la Lista de Cursos Gratuitos
export const getFreeCoursesList = async () => {
  let data = [];
  const q = query(collection(db, collCourses), where('isFree', '==', true));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};
//Obtenemos la Lista de Cursos Pagados
export const getPaidCoursesList = async () => {
  let data = [];
  const q = query(collection(db, collCourses), where('isFree', '==', false));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};
//Obtenemos los Datos de un Curso por ID
export async function getCourseData(id) {
  let data = [];
  const q = query(collection(db, collCourses), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
}
//Obtenemos los Comentarios de un Curso por ID
export async function getCourseComments(id) {
  let data = [];
  const q = query(collection(db, collComments), where('idCourse', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
}

//Obtenemos los Comentarios de un Curso por ID y que no esten pendientes
export async function getCourseAprobeComments(id) {
  let data = [];
  const q = query(collection(db, collComments), where('idCourse', '==', id), where('state', '==', 1));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
}
//Obtenemos cantidad de Usuarios Registrados
export const countComments = async (id) => {
  const q = query(collection(db, collComments), where('idCourse', '==', id), where('state', '==', 1));
  const querySnapshot = await getDocs(q);
  const count = querySnapshot.size;
  return count;
};
//Obtenemos los Datos de un Modulo por ID de Curso
export async function getModuleData(id) {
  let data = [];
  const q = query(collection(db, collModules), where('idCourse', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
    data.sort((a, b) => a.order - b.order);
  });
  return data;
}
//Obtenemos los Datos de un Modulo por ID de Curso
export async function getUserInformation(id) {
  let data = [];
  const q = query(collection(db, collUsers), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
}
//Obtenemos el nombre y apellido de Usuario por ID
export async function getUserName(id) {
  let name = null;
  const q = query(collection(db, collUsers), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    name = doc.data().name + ' ' + doc.data().lastName;
  });
  return name;
}

//STADISTICS COUNT ITEMS
//Obtenemos cantidad de Usuarios Registrados
export const countUser = async () => {
  const q = query(collection(db, collUsers), where('profile', '==', genConst.CONST_PRO_STU));
  const querySnapshot = await getDocs(q);
  const count = querySnapshot.size;
  return count;
};
//Obtenemos cantidad de Usuarios Administradores Registrados
export const countAdminUser = async () => {
  const q = query(collection(db, collUsers), where('profile', '==', genConst.CONST_PRO_ADM));
  const querySnapshot = await getDocs(q);
  const count = querySnapshot.size;
  return count;
};
//Obtenemos cantidad de Operadores
export const countOperators = async () => {
  const q = query(collection(db, collUsers), where('profile', '==', genConst.CONST_PRO_OPE));
  const querySnapshot = await getDocs(q);
  const count = querySnapshot.size;
  return count;
};
//Obtenemos cantidad de Agencias
export const countAgencies = async () => {
  const collCount = collection(db, collAgencies);
  const querySnapshot = await getDocs(collCount);
  const count = querySnapshot.size;
  return count;
};
//Obtenemos cantidad de Servicios
export const countServices = async () => {
  const collCount = collection(db, collServices);
  const querySnapshot = await getDocs(collCount);
  const count = querySnapshot.size;
  return count;
};
//Obtenemos cantidad de Negocios Registrados
export const countBusiness = async () => {
  const collCount = collection(db, collBusiness);
  const querySnapshot = await getDocs(collCount);
  const count = querySnapshot.size;
  return count;
};
//Obtenemos cantidad de Negocios Registrados por Usuario ID
export async function countBusinessById(id) {
  const q = query(collection(db, collBusiness), where('userId', '==', id));
  const querySnapshot = await getDocs(q);
  const count = querySnapshot.size;
  return count;
}
//Obtenemos cantidad de Cursos Registrados
export const countCourses = async () => {
  const coursesCollection = collection(db, collCourses);
  const querySnapshot = await getDocs(coursesCollection);
  const courseCount = querySnapshot.size;
  return courseCount;
};
//Obtenemos cantidad de Subscripciones Registradas
export const countSubscriptions = async () => {
  const subsCollection = collection(db, collSubscription);
  const querySnapshot = await getDocs(subsCollection);
  const subsCount = querySnapshot.size;
  return subsCount;
};
//Obtenemos cantidad de Subscripciones Activas Registradas
export async function countActiveSubscriptions() {
  const q = query(collection(db, collSubscription), where('state', '==', 1));
  const querySnapshot = await getDocs(q);
  const count = querySnapshot.size;
  return count;
}
//LOGIN
//Buscamos si correo corresponde a Administrador
export const getAdmin = async (mail) => {
  let isFind = false;
  const q = query(collection(db, collAdminUsers), where('email', '==', mail));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.size > 0) {
    isFind = true;
  } else {
    isFind = false;
  }
  return isFind;
};

export const getUserDataObject = () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(
      authentication,
      (user) => {
        if (user) {
          resolve(user);
        } else {
          resolve(null);
        }
      },
      reject
    );
  });
};

export async function getUserCourses(id) {
  let courses = [];
  const q = query(collection(db, collRegUsr), where('idIUser', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    courses.push(doc.data());
  });
  return courses;
}
