// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const ctaAccount = 'CTAACC';
export const serviceImage =
  'https://firebasestorage.googleapis.com/v0/b/auto-clean-and-green.appspot.com/o/assets%2Fcarwash.png?alt=media&token=3646ff16-06b0-4db1-99bb-43b97d043eaf';
export const detailingImage =
  'https://firebasestorage.googleapis.com/v0/b/auto-clean-and-green.appspot.com/o/assets%2Fdetailing.png?alt=media&token=d7f34e77-a062-4503-9db1-46901ae2efb2';
export const agencyImage =
  'https://firebasestorage.googleapis.com/v0/b/auto-clean-and-green.appspot.com/o/assets%2Fmall.png?alt=media&token=d918b91f-b9a0-4445-a96a-8b5887c53a49';
export const cityImage =
  'https://firebasestorage.googleapis.com/v0/b/auto-clean-and-green.appspot.com/o/assets%2Fmap-pin-green.png?alt=media&token=186f13cc-8d42-4b1f-9742-19e94680710f';

export const bankData = {
  BANK_TYPE_1: 'Ahorros',
  BANK_TYPE_2: 'Corriente',
  BANK_NAME_1: 'Banco Pichincha',
  BANK_CTA_1: '600000001',
  BANK_CTA_USR_1: 'Sebastian Ándres Orellana Vallejo',
  BANK_CTA_ID_1: '1712000000',
  BANK_NAME_2: 'Banco Del Pacífico',
  BANK_CTA_2: '600000002',
  BANK_CTA_USR_2: 'Sebastian Ándres Orellana Vallejo',
  BANK_CTA_ID_2: '1712000000',
  BANK_NAME_3: 'Banco Produbanco',
  BANK_CTA_3: '600000003',
  BANK_CTA_USR_3: 'Sebastian Ándres Orellana Vallejo',
  BANK_CTA_ID_3: '1712000000'
};
export const mercadoPago = {
  TITLE: 'Mercado Pago',
  SUBTITLE: 'Muy Pronto!!!',
  DESC: '09876266272'
};

export const cities = {
  CITY1_VAL: 'Quito',
  CITY1_CODE: 1,
  CITY2_VAL: 'Guayaquil',
  CITY2_CODE: 2
};

export const carSizes = {
  CAR_SIZE_1: 'S',
  CAR_SIZE_1_DESC: 'PEQUEÑO',
  CAR_SIZE_2: 'M',
  CAR_SIZE_2_DESC: 'MEDIANO',
  CAR_SIZE_3: 'L',
  CAR_SIZE_3_DESC: 'GRANDE'
};

export const genConst = {
  CONST_PRO_ADM: 1001,
  CONST_PRO_STU: 2002,
  CONST_PRO_OPE: 3003,
  CONST_ADM_NOT: 'Administrador del Sistema',
  CONST_PRO_ADM_TXT: 'Administrador',
  CONST_PRO_STU_TXT: 'Usuario',
  CONST_PRO_OPE_TXT: 'Operador',
  CONST_STA_ACT: 1,
  CONST_STA_INACT: 2,
  CONST_STATE_IN: 0,
  CONST_STATE_AC: 1,
  CONST_STATE_PN: 2,
  CONST_NOTIF_NL: 0,
  CONST_NOTIF_LE: 1,
  CONST_STATE_APR: 1,
  CONST_STATE_NO_APR: 2,
  CONST_STA_ACT_TXT: 'Activo',
  CONST_STA_INACT_TXT: 'Inactivo',
  CONST_STA_APR_TXT: 'Aprobado',
  CONST_STA_NO_APRO_TXT: 'Pendiente',
  CONST_EXT_IMAGE: '.jpg',
  CONST_EXT_PDF: '.pdf',
  CONST_EXT_DOC: '.doc',
  CONST_EXT_VIDEO_MP4: '.mp4',
  CONST_EXT_VIDEO_AVI: '.avi',
  CONST_PRIMARY_COLOR: '#53338a',
  CONST_SECONDARY_COLOR: '#a8afda',
  CONST_THIRD_COLOR: '#04a604',
  CONST_CREATE_COLOR: '#38E169',
  CONST_VIEW_COLOR: '#b1cede',
  CONST_UPDATE_COLOR: '#01c38eff',
  CONST_DELETE_COLOR: '#f66374',
  CONST_CANCEL_COLOR: '#b1cede',
  CONST_SUCCESS_COLOR: '#38E169',
  CONST_INFO_COLOR: '#b1cede',
  CONST_ERROR_COLOR: '#f66374',
  CONST_WARNING_COLOR: '#fffaa4',
  CONST_APPBAR_SEARCH: '#f5f6f8',
  CONST_APPBAR: '#0acd57',
  CONST_MTD_1_LBL: 'Tarjeta Débito / Crédito',
  CONST_MTD_2_LBL: 'Depósito / Transferencia',
  CONST_MTD_3_LBL: 'De Una',
  CONST_MTD_4_LBL: 'PayPal',
  CONST_MTD_5_LBL: 'Mercado Pago',
  CONST_MTD_6_LBL: 'Otro',
  CONST_MTD_7_LBL: 'Otro',
  CONST_MTD_1_VAL: 1,
  CONST_MTD_2_VAL: 2,
  CONST_MTD_3_VAL: 3,
  CONST_MTD_4_VAL: 4,
  CONST_MTD_5_VAL: 5,
  CONST_MTD_6_VAL: 6,
  CONST_CTA_AHO_DES: 'CTA. AHORROS',
  CONST_CTA_AHO_VAL: 'CTAAHO',
  CONST_CTA_COR_DES: 'CTA. CORRIENTE',
  CONST_CTA_COR_VAL: 'CTACORR',
  CONST_MONTH_VALUE: 30,
  CONST_YEAR_VALUE: 300,
  CONST_MONTH_DAYS: 30,
  CONST_YEAR_DAYS: 365,
  CONST_MONTH_CHAR: 1,
  CONST_YEAR_CHAR: 2,
  CONST_IVA: 0.12
};
