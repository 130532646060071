// assets
import {
  IconDashboard,
  IconUsers,
  IconFriends,
  IconBell,
  IconBook,
  IconBuildingBank,
  IconCar,
  IconUserCircle,
  IconFileDollar,
  IconCarTurbine,
  IconReceipt
} from '@tabler/icons';

// constant
const icons = {
  IconDashboard,
  IconUsers,
  IconFriends,
  IconBell,
  IconFileDollar,
  IconBook,
  IconBuildingBank,
  IconCar,
  IconUserCircle,
  IconCarTurbine,
  IconReceipt
};

const dashboard = {
  id: 'dashboard',
  title: 'Panel Principal',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Panel Principal',
      type: 'item',
      url: '/admin/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'admins',
      title: 'Administradores',
      type: 'item',
      url: '/admin/admin-users',
      icon: icons.IconUsers,
      breadcrumbs: false
    },
    {
      id: 'users',
      title: 'Usuarios',
      type: 'item',
      url: '/admin/users',
      icon: icons.IconUsers,
      breadcrumbs: false
    },
    {
      id: 'operators',
      title: 'Operadores',
      type: 'item',
      url: '/admin/operators',
      icon: icons.IconUserCircle,
      breadcrumbs: false
    },
    {
      id: 'payments',
      title: 'Pagos',
      type: 'item',
      url: '/admin/operators',
      icon: icons.IconFileDollar,
      breadcrumbs: false
    },
    {
      id: 'agency',
      title: 'Agencias / Ciudades',
      type: 'item',
      url: '/admin/agency',
      icon: icons.IconBuildingBank,
      breadcrumbs: false
    },
    {
      id: 'services',
      title: 'Servicios / Detailing',
      type: 'item',
      url: '/admin/services',
      icon: icons.IconCar,
      breadcrumbs: false
    },
    {
      id: 'cars',
      title: 'Vehículos',
      type: 'item',
      url: '/admin/cars',
      icon: icons.IconCarTurbine,
      breadcrumbs: false
    },
    {
      id: 'orders',
      title: 'Ordenes',
      type: 'item',
      url: '/admin/orders',
      icon: icons.IconReceipt,
      breadcrumbs: false
    },
    {
      id: 'notifications',
      title: 'Notificaciones',
      type: 'item',
      url: '/admin/notifications',
      icon: icons.IconBell
    }
  ]
};

export default dashboard;
